<template>
  <div>
    <div class="page-title">
      <h3>Проекты</h3>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Добавить'"
           @click="$router.push('/projects/add')">
          <i class="material-icons">add</i>
        </a>
      </span>
    </div>

    <Loader v-if="loading"/>

    <p class="center" v-else-if="!records.length">
      {{'Нет записей'}}
    </p>

    <section v-else>
      <ProjectsTable :records="records"/>
    </section>
  </div>
</template>

<script>
  import paginationMixin from '@/mixins/pagination.mixin'
  import ProjectsTable from '@/components/ProjectsTable'

  export default {
    name: 'projects',
    metaInfo() {
      return {
        title: this.$title('Проекты')
      }
    },
    mixins: [paginationMixin],
    data: () => ({
      loading: true,
      records: []
    }),
    async mounted() {
      this.records = await this.$store.dispatch('fetchProjects')
      this.loading = false
    },
    methods: {
    },
    components: {
      ProjectsTable
    }
  }
</script>
